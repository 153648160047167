export var baseUrl = process.env.NEXT_PUBLIC_BIOCENTURY_URL;
export var companyProfileUrl = "".concat(process.env.NEXT_PUBLIC_BCIQ_PROFILES_URL, "/companies");
export var ArticlesEndpoints;
(function(ArticlesEndpoints) {
    ArticlesEndpoints["DECRYPT_ARTICLE"] = "/api/article";
    ArticlesEndpoints["GET_ARTICLES_EDITION"] = "/api/edition";
})(ArticlesEndpoints || (ArticlesEndpoints = {}));
export var AlgoliaEndpoints;
(function(AlgoliaEndpoints) {
    AlgoliaEndpoints["INDEX_RECORD"] = "/api/algolia/index-record";
    AlgoliaEndpoints["INDEX_ALL"] = "/api/algolia/index-all-records";
    AlgoliaEndpoints["DELETE_RECORD"] = "/api/algolia/delete-record";
    AlgoliaEndpoints["PROFILE_URL"] = "/:encondedName";
})(AlgoliaEndpoints || (AlgoliaEndpoints = {}));
export var MainDatabaseEndpoints = {
    FETCH_ENTITIES: "".concat(process.env.BIOCENTURY_API_URL, "/search/v1/entityfetch/:modelType"),
    FETCH_ENTITIES_WITH_PAGE_SIZE: "".concat(process.env.BIOCENTURY_API_URL, "/search/v1/entityfetch/:modelType?pageSize=:pageSize&pageNumber=0")
};
