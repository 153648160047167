import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import regeneratorRuntime from "/home/vsts/work/1/s/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { baseUrl, AlgoliaEndpoints } from "src/utils/endpoints";
import fetch from "cross-fetch";
var __awaiter = this && this.__awaiter || function(thisArg, _arguments, P, generator) {
    var adopt = function adopt(value) {
        return _instanceof(value, P) ? value : new P(function(resolve) {
            resolve(value);
        });
    };
    return new (P || (P = Promise))(function(resolve, reject) {
        var fulfilled = function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        };
        var rejected = function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        };
        var step = function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        };
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var indexRecord = function(indexRecordParams) {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var url, record, modelType, response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    url = "".concat(baseUrl).concat(AlgoliaEndpoints.INDEX_RECORD);
                    record = indexRecordParams.record, modelType = indexRecordParams.modelType;
                    _ctx.next = 4;
                    return fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            record: record,
                            modelType: modelType
                        })
                    });
                case 4:
                    response = _ctx.sent;
                    return _ctx.abrupt("return", response.json());
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
export var indexAllRecords = function(indexAllParams) {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var modelType, url, response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    modelType = indexAllParams.modelType;
                    url = "".concat(baseUrl).concat(AlgoliaEndpoints.INDEX_ALL);
                    _ctx.next = 4;
                    return fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            modelType: modelType
                        })
                    });
                case 4:
                    response = _ctx.sent;
                    return _ctx.abrupt("return", response.json());
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
export var deleteRecord = function(deleteRecordParams) {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var objectID, modelType, url, response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    objectID = deleteRecordParams.objectID, modelType = deleteRecordParams.modelType;
                    url = "".concat(baseUrl).concat(AlgoliaEndpoints.DELETE_RECORD);
                    _ctx.next = 4;
                    return fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            objectID: objectID,
                            modelType: modelType
                        })
                    });
                case 4:
                    response = _ctx.sent;
                    return _ctx.abrupt("return", response.json());
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
